module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'dust-songs',
  title: 'Dust Songs',
  date: '2014-07-01',
  label: 'Denovali Records',
  player: '302439916',
  image: '/images/tss-dust-songs.jpg',
  links: [
    {
      title: 'CD / Vinyl',
      href: 'https://denovali.com/store/?main_page=advanced_search&s=dust%20towers',
    },
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/dust-songs',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/7femNx05OKn3syyJjOmLJb',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/au/album/dust-songs/1518405611',
    },
  ]
}
