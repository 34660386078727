module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'compressor',
  title: 'Compressor',
  date: '2007-07-01',
  label: 'Extreme Music',
  player: '1480314287',
  image: '/images/tss-compressor.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/compressor',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/0mKRYiDWmQIDcK2syHHbb4',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/compressor/218990165',
    },
  ]
}
