module.exports = {
  slug: 'halo',
  name: 'HALO',
  links: [
    {
      'title': 'Bandcamp (Self-released)',
      'href': 'https://fearhatelies.bandcamp.com',
    },
    {
      'title': 'Bandcamp (Relapse Records)',
      'href': 'https://haloband.bandcamp.com',
    }
  ],
}
