module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'constructing-towers',
  title: 'Constructing Towers',
  date: '2008-07-01',
  label: 'Extreme Music / Denovali Records (2012 reissue)',
  player: '2914239840',
  image: '/images/tss-constructing-towers.jpg',
  links: [
    {
      title: 'CD / Vinyl',
      href: 'https://denovali.com/store/?main_page=advanced_search&s=constructing%20towers',
    },
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/constructing-towers',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/75W0civPHXj0OdETC9Fb9B',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/constructing-towers/294304227',
    },
  ]
}
