module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'heavy-weather',
  title: 'Heavy Weather',
  date: '2011-07-01',
  label: 'Denovali Records',
  player: '3744925229',
  image: '/images/tss-heavy-weather.jpg',
  links: [
    {
      title: 'CD / Vinyl',
      href: 'https://denovali.com/store/?main_page=advanced_search&s=heavy%20weather',
    },
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/heavy-weather',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/54BKzrTerhWs6FFEbcWMKA',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/heavy-weather/1518335601',
    },
  ]
}
