module.exports = {
  slug: 'a-beautiful-machine',
  name: 'A Beautiful Machine',
  links: [
    {
      'title': 'Website',
      'href': 'https://abeautifulmachine.co',
    },
    {
      'title': 'Bandcamp',
      'href': 'https://abeautifulmachine.bandcamp.com',
    }
  ],
}
