module.exports = {
  slug: 'terminal-sound-system',
  name: 'Terminal Sound System',
  links: [
    {
      'title': 'Website',
      'href': 'http://terminalsoundsystem.com',
    },
    {
      'title': 'Bandcamp',
      'href': 'https://terminalsoundsystem.bandcamp.com',
    }
  ],
}
