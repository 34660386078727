module.exports = {
  slug: 'filament-twine',
  name: 'Filament + Twine',
  links: [
    {
      'title': 'Bandcamp',
      'href': 'https://ftwine.bandcamp.com',
    }
  ],
}
