module.exports = {
  slug: 'tether',
  name: 'Tether',
  links: [
    {
      'title': 'Bandcamp',
      'href': 'https://tether.bandcamp.com',
    }
  ],
}
