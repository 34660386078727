module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'black-note-ep',
  title: 'Black Note EP',
  date: '2003-07-01',
  label: 'Embryo Recordings',
  player: '1168281750',
  image: '/images/tss-black-note-ep.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/black-note-ep',
    },
  ]
}
