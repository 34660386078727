module.exports = {
  slug: 'anti-sound-syndicate',
  name: 'Anti Sound Syndicate',
  links: [
    {
      'title': 'Bandcamp',
      'href': 'https://antisoundsyndicate.bandcamp.com',
    }
  ],
}
