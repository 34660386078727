module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'solaris',
  title: 'Solaris',
  date: '1999-07-01',
  label: 'Embryo Recordings',
  player: '183237902',
  image: '/images/tss-solaris.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/solaris',
    },
  ]
}
