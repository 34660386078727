import projects from '../data/projects'
import releases from '../data/releases'

const getProject = (slug) => {
  return projects.filter(project => {
    return slug === project.slug;
  }).pop()
}

const getProjects = () => {
  return projects;
}

const getReleases = (slug) => {
  return releases.filter(release => {
    return !slug || slug === release.project.slug;
  })
}

export default {
  getProject: getProject,
  getProjects: getProjects,
  getReleases: getReleases,
}
