import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

export default ({items, project}) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Link to={`/releases/${item.slug}`}>
            {project ? `${item.project.name} - ` : null}
            {item.title}
          </Link>
          <small>
            {moment(item.date).format('Y')}
          </small>
        </li>
      ))}
    </ul>
  )
}
