module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'the-endless-sea',
  title: 'The Endless Sea',
  date: '2018-07-01',
  label: 'Denovali Records',
  player: '3111583016',
  image: '/images/tss-dust-songs.jpg',
  links: [
    {
      title: 'CD / Vinyl',
      href: 'https://denovali.com/store/?main_page=advanced_search&s=the%20endless%20sea',
    },
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/the-endless-sea',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/6HO2uh8vvaDBWx1xyleP4u',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/the-endless-sea/1518402992',
    },
  ]
}
