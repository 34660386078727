module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'a-sun-spinning-backwards',
  title: 'A Sun Spinning Backwards',
  date: '2013-07-01',
  label: 'Denovali Records',
  player: '942493541',
  image: '/images/tss-a-sun-spinning-backwards.jpg',
  links: [
    {
      title: 'CD / Vinyl',
      href: 'https://denovali.com/store/?main_page=advanced_search&s=a%20sun%20spinning%20backwards',
    },
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/a-sun-spinning-backwards',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/5VsnkqnA9kZYoks8PD6h3m',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/a-sun-spinning-backwards/1518321211',
    },
  ]
}
