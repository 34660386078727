module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'last-night-i-dreamed-of-armageddon',
  title: 'Last Night I Dreamed of Armageddon',
  date: '2004-07-01',
  label: 'Hive Records',
  player: '4117997803',
  image: '/images/tss-last-night-i-dreamed-of-armageddon.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/last-night-i-dreamed-of-armageddon',
    },
  ]
}
