module.exports = {
  slug: 'siilt',
  name: 'Siilt',
  links: [
    {
      'title': 'Bandcamp',
      'href': 'https://siilt.bandcamp.com',
    }
  ],
}
